<template>
  <b-row>
    <b-col cols="12">
      <b-card no-body class="mb-4">
        <b-card-body class="p-3">
          <h5 class="card-title mb-0">All Resources</h5>
        </b-card-body>
        <div class="p-3 d-flex align-items-center">
          <b-form-group
            label="Per page"
            label-for="per-page-select"
            label-cols-sm="6"
            label-cols-md="4"
            label-cols-lg="9"
            label-size="sm"
            label-class="fw-medium"
            class="mb-0"
          >
            <b-form-select
              id="per-page-select"
              v-model="perPage"
              :options="pageOptions"
              size="sm"
            ></b-form-select>
          </b-form-group>
          <div class="ml-auto">
            <b-pagination
              v-model="currentPage"
              :total-rows="totalRows"
              :per-page="perPage"
              align="fill"
              class="my-0"
            ></b-pagination>
          </div>
        </div>

        <b-table
          responsive
          class="mb-0"
          head-variant="light"
          :fields="fields"
          :items="items"
          :current-page="currentPage"
          :per-page="perPage"

        >
          <template #cell(actions)="data">
            <b-button variant="light" class="sm" @click="editDept(data.item.id)">
              <feather type="edit" class="feather-sm"></feather>
            </b-button>
            <b-button variant="danger" class="sm" style="margin:3px" autofocus @click="deleteDept(data.item.id)">
              <feather type="trash" class="feather-sm" ></feather>
            </b-button>
         </template>
        
        </b-table>
      </b-card>
    </b-col>
  </b-row>
</template>

<script>
import { mapState, mapActions } from "vuex";
export default {
  name: "Departments",

  data: () => ({
    page: {
      title: "PaginationTable",
    },
    fields:["id","department_name","department_head_name","status","location_id","actions"],
    items: [],
    totalRows: 1,
    currentPage: 1,
    perPage: 5,
    pageOptions: [5, 10, 15, { value: 100, text: "Show a lot" }],
  }),
  components: {},
  computed: {
    ...mapState({
      authId: (state) => state.auth.authId
    })
  },
  mounted() {
    this.loadDepartemens(parseInt(this.authId));
  },
  methods: {
    ...mapActions(["getDepartements", "deleteDepartment"]),
    loadDepartemens(user_id) {
      this.getDepartements().then((res) => {
        this.items = res.filter(d => { return d.user_id === user_id });
        this.totalRows = this.items.length;
      });
    },

    //Applying delete method in the service. 
    deleteDept(id){
      this.deleteDepartment({'id': id}).then(()=>{
        this.loadDepartemens();
      })
    },
    editDept(id){
      this.$router.push({ name: "Update Department", params:{ id: id} })
    }
  },
};
</script>